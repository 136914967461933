import React from 'react';
import './styles.css';
import { IconLogoGetEnergy } from './ui';

const GetEnergyPage = () => {
  return (
    <div className="placeholder-container">
      <IconLogoGetEnergy className="logo-icon" />

      {/* Content */}
      <div className="content">
        <div>
          <h2>Ведутся технические работы</h2>
          <p>Заряжайтесь в нашем приложении Get Energy</p>

          <div className="app-download">
            <div className="app-icon">
              <img src={require('./app-icon.svg')} alt="Getenergy icon" />
            </div>
            <div className="app-info">
              <p className="app-title">Get Energy</p>
              <p className="app-subtitle">Бесплатно в App Store и Google Play</p>
            </div>
            <div className="app-buttons">
              <div>
                <a href="https://apps.apple.com/ru/app/get-energy/id1531076906">
                  <img src={require('./apple.svg')} alt="App Store" />
                </a>
              </div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.getenergy">
                  <img src={require('./google.svg')} alt="Google Play" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Support */}
      <div className="support">
        <p>Поддержка</p>
        <p className="phone">+7 (495) 642-27-56, 8 (800) 550-16-32</p>
      </div>
    </div>
  );
};

export default GetEnergyPage;
