import React from 'react';
import ReactDOM from 'react-dom';
import { init as SentryInit } from '@sentry/browser';

// import 'smartbanner.js/dist/smartbanner';
import 'smartbanner.js/dist/smartbanner.min.css';

import 'static/src/styles/styles.pcss';

import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { Placeholder } from './pages/Placeholder';

if (process.env.NODE_ENV === 'production') {
  SentryInit({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
  });
}

let MainApp = <App />;
const isPlaceholder = false;
if (isPlaceholder) {
  MainApp = <Placeholder />;
}

ReactDOM.render(MainApp, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
