import React, { PureComponent, RefObject } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { inject, observer } from 'mobx-react';
import { IStoreMain } from '../../store';
import { GeMap } from '../../store/generated-api';
import { IStore } from '../../store/modules';

import { IconPosition } from '../../ui/Icons';
import debounce from 'lodash.debounce';

declare var google: any;

type Props = {
  points: GeMap[];
  location: { lat: number; lng: number } | null;
  onGeolocation(): void;
  onPointClick(point: GeMap): void;
  store?: IStore;
};

const KEY = 'AIzaSyBcroXe6QdmtY8myUnLNSWtUK04hBTl8kQ';
const STYLES = [
  { featureType: 'poi.attraction', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.business', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.government', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.medical', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.park', elementType: 'labels.text', stylers: [{ visibility: 'simplified' }] },
  { featureType: 'poi.place_of_worship', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.school', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.sports_complex', stylers: [{ visibility: 'off' }] },
];

@inject('store')
@observer
export class Map extends PureComponent<Props> {
  private map: RefObject<GoogleMap> = React.createRef();
  private ready = false;
  private MapComponent = withScriptjs(
    withGoogleMap(({ center, ...restProps }: any) => (
      <GoogleMap
        ref={this.map}
        defaultZoom={center ? 12 : Math.ceil(5 * Math.pow(window.innerWidth / 1920, 0.5))}
        defaultCenter={center || { lat: 55.1516878, lng: 61.1283951 }}
        options={{ disableDefaultUI: true, clickableIcons: false, styles: STYLES }}
        onIdle={this.handleIdle}
        {...restProps}
      />
    )),
  ) as any;

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.points.length && this.props.points.length && this.ready) {
      this.showClosestStation();
    }
  }

  handleIdle = () => {
    if (this.ready) return;
    this.ready = true;
    this.showClosestStation();
  };

  handleBoundsChanged = debounce(() => {
    const { map } = this.props.store!;
    if (this.map.current) {
      const newCenter = this.map.current.getCenter();

      const centerCoords = {
        latitude: newCenter.lat(),
        longitude: newCenter.lng(),
      };
      const bounds = this.map.current.getBounds();
      if (bounds) {
        const ne = bounds.getNorthEast();
        const distanceInKm = google.maps.geometry.spherical.computeDistanceBetween(newCenter, ne) / 1000;

        map.mapLight({
          ...centerCoords,
          radius: distanceInKm,
        });
      }
    }
  }, 500);

  showClosestStation = () => {
    const { location, points } = this.props;

    let closestPoint: any;
    let minDistance = Infinity;

    if (!location) {
      this.handleFitBounds();
      return;
    }

    points.forEach((p: any) => {
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(location.lat, location.lng),
        new google.maps.LatLng(p.latitude, p.longitude),
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestPoint = p;
      }
    });

    if (!closestPoint) return;

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(location);
    bounds.extend({ lat: closestPoint.latitude, lng: closestPoint.longitude });
    setTimeout(() => this.map.current && this.map.current.fitBounds(bounds), 500);
  };

  handleFitBounds = () => {
    const { points } = this.props;
    const bounds = points.reduce(
      (bounds: any, point: any) => bounds.extend({ lat: point.latitude, lng: point.longitude }),
      new google.maps.LatLngBounds(),
    );
    setTimeout(() => this.map.current && this.map.current.fitBounds(bounds), 500);
  };

  render() {
    const { location, points, onGeolocation } = this.props;
    console.log("render")
    return (
      <div style={{ position: 'relative', height: '100%' }}>
        {location && points.length > 0 && (
          <button className="bMap__geolocationBtn" onClick={this.showClosestStation}>
            <IconPosition />
          </button>
        )}
        <this.MapComponent
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${KEY}`}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '100%' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          center={location}
          onBoundsChanged={this.handleBoundsChanged}
        >
          {location && <Marker position={location} icon="assets/icon_position.svg" />}
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            styles={[
              {
                url: 'assets/icon_cluster.svg',
                width: 56,
                height: 56,
                fontFamily: 'Acrom, Arial, sans-serif',
                fontWeight: 'bold',
                textColor: '#FFF',
                textSize: 14,
              },
            ]}
          >
            {points.map((p: any) => (
              <Marker
                key={`${p.locationId}-${p.latitude}-${p.longitude}`}
                position={{ lat: p.latitude, lng: p.longitude }}
                icon={p.active ? 'assets/icon_marker.svg' : 'assets/icon_marker_disabled.svg'}
                onClick={() => this.props.onPointClick(p)}
              />
            ))}
          </MarkerClusterer>
        </this.MapComponent>
      </div>
    );
  }
}
